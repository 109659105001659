import image1 from './images/kherson/1.jpg'
import image2 from './images/kherson/2.jpg'
import image3 from './images/kherson/3.jpg'
import image4 from './images/kherson/4.jpg'
import image5 from './images/kherson/5.jpg'
import image6 from './images/kherson/6.jpg'
import image7 from './images/kherson/7.jpg'
import image8 from './images/kherson/8.jpg'

const khersonData = [
    {
        src: `${image1}`
    },
    {
        src: `${image2}`
    },
    {
        src: `${image3}`
    },
    {
        src: `${image4}`
    },
    {
        src: `${image5}`
    },
    {
        src: `${image6}`
    },
    {
        src: `${image7}`
    },
    {
        src: `${image8}`
    }
]

export default khersonData;
