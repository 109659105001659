import image1 from './images/livan/5.webp'
import image2 from './images/livan/5.webp'
import image3 from './images/livan/5.webp'
import image4 from './images/livan/5.webp'
import image5 from './images/livan/5.webp'

const livanData = [
    {
        src: `${image1}`
    },
    {
        src: `${image2}`
    },
    {
        src: `${image3}`
    },
    {
        src: `${image4}`
    },
    {
        src: `${image5}`
    }
]

export default livanData;
