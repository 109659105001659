import image1 from './images/lisnyky/1.jpeg'
import image2 from './images/lisnyky/2.jpeg'
import image3 from './images/lisnyky/3.jpeg'
import image4 from './images/lisnyky/4.jpeg'
import image5 from './images/lisnyky/5.jpeg'
import image6 from './images/lisnyky/6.jpeg'
import image7 from './images/lisnyky/7.jpeg'
import image8 from './images/lisnyky/8.jpeg'
import image9 from './images/lisnyky/9.jpeg'
import image10 from './images/lisnyky/10.jpeg'
import image11 from './images/lisnyky/11.jpeg'
import image12 from './images/lisnyky/12.jpeg'

const lisnykyData = [
    {
        src: `${image1}`
    },
    {
        src: `${image2}`
    },
    {
        src: `${image3}`
    },
    {
        src: `${image4}`
    },
    {
        src: `${image5}`
    },
    {
        src: `${image6}`
    },
    {
        src: `${image7}`
    },
    {
        src: `${image8}`
    },
    {
        src: `${image9}`
    },
    {
        src: `${image10}`
    },
    {
        src: `${image11}`
    },
    {
        src: `${image12}`
    }
]

export default lisnykyData;
