import image1 from './images/kpi/6.webp'
import image2 from './images/kpi/6.webp'
import image3 from './images/kpi/6.webp'
import image4 from './images/kpi/6.webp'
import image5 from './images/kpi/6.webp'
import image6 from './images/kpi/6.webp'

const kpiData = [
    {
        src: `${image1}`
    },
    {
        src: `${image2}`
    },
    {
        src: `${image3}`
    },
    {
        src: `${image4}`
    },
    {
        src: `${image5}`
    },
    {
        src: `${image6}`
    }
]

export default kpiData;
