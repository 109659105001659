import image1 from "./images/aks/1.jpg"
import image2 from "./images/aks/2.jpg"
import image3 from "./images/aks/3.jpg"
import image4 from "./images/aks/4.jpg"
import image5 from "./images/aks/5.jpg"
import image6 from "./images/aks/6.jpg"
import image7 from "./images/aks/7.jpg"
import image8 from "./images/aks/8.jpg"

const aksData = [
    {
        src: `${image1}`
    },
    {
        src: `${image2}`
    },
    {
        src: `${image3}`
    },
    {
        src: `${image4}`
    },
    {
        src: `${image5}`
    },
    {
        src: `${image6}`
    },
    {
        src: `${image7}`
    },
    {
        src: `${image8}`
    },

]

export default aksData;
