import image1 from "./images/concreting/1.jpg"
import image2 from "./images/concreting/2.jpg"
import image3 from "./images/concreting/3.jpg"
import image4 from "./images/concreting/4.jpg"
import image5 from "./images/concreting/5.jpg"

const concretingData = [
    {
        src: `${image1}`
    },
    {
        src: `${image2}`
    },
    {
        src: `${image3}`
    },
    {
        src: `${image4}`
    },
    {
        src: `${image5}`
    },

]

export default concretingData;
