import image1 from './images/seams/3.webp'
import image2 from './images/seams/3.webp'
import image3 from './images/seams/3.webp'

const seamsData = [
    {
        src: `${image1}`
    },
    {
        src: `${image2}`
    },
    {
        src: `${image3}`
    }
]

export default seamsData;
